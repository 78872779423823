import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const RelySEO = () => (
  <Layout>
    <SEO
      title="Why You Shouldn’t Just Rely on SEO"
      description="Ever changing algorithm - SEO is an amazing tool if you get it right. It can be frustrating initially because it usually takes a while to see any worthwhile results"
    />
    <h1>Why You Shouldn’t Just Rely on SEO</h1>
    <p style={{fontSize:"1.2rem", color:"steelBlue"}}><b>Why you should use multiple types of marketing</b></p>

    <h2>The Ever Changing Algorithm</h2>
    <p>SEO is an amazing tool if you get it right. It can be frustrating initially because it usually takes a while to see any worthwhile results. The effort is well worth it though because when you do get it right, it becomes a source of free traffic. It’s never really free because it takes maintenance to keep a website going but with very little input, you can see a steady stream of new customers. With all the benefits, there are some major drawbacks of SEO that people don’t often talk about. One of them is the ever changing algorithm.</p>
   
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/kZr5JsD.jpg"
        alt="Why You Shouldn’t Just Rely on SEO"
        description="a basket of eggs i.e. don't put all your SEO eggs in one basket"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@freestocks"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      freestocks
      </a>
      {" "} on Unsplash
    </p>
    
    <p>Google often updates its algorithm. This is the brain that makes the search engine run. There are multiple reasons for updating the algorithm and the main one is for a better customer experience. Google wants to provide an answer or solution for every query which means serving up information that does just that.</p>
    <p>If your site meets the criteria to be ranked highly for certain queries then great, lots of traffic but don’t get too comfortable. If your site doesn’t meet the criteria for the most recent update, you could see your ranking plummet. Usually it will fall a few places but if the new ranking factors are particularly harsh, you could see your ranking fall a few pages.</p>

    <p>See search console, it's a great tool and look out for the following. Getting these right can make all the difference right now.</p>
    <ul>
        <li>speed</li>
        <li>mobile responsiveness</li>
        <li>CLS (cumulative layout shift), FCT (first contextual paint)</li>
    </ul>

    <h2>Reach Different Types of Audiences</h2>
    <p>One of the huge benefits of using multiple types of marketing is that you can reach all kinds of people. Each demographic responds in a different way and it’s important to figure out which works best for you. Instagram has a younger user base than Linkedin for example. People using instagram might also be in an entertainment or fun frame of mind.</p>
    <p>People using linkedin might be in a more serious frame of mind. It’s not just the person but the state they are in when they find your ad, therefore ads can be set up to have a different message on each platform but essentially sell the same product.</p>
    <p>Then there’s the more traditional types of marketing like radio ads. There are still a lot of radio ads and they are still very effective, especially for local services. With regards to audio advertising, there’s also podcast ads. I find that these work best when you buy slots. A listener is unlikely to take action on your ad the first time they hear it but with lots of repetition, your message will slowly sink in.</p>
    <p>The negative is that if you are doing this by yourself then it's easy to draw yourself thin. When that happens, you end up having mediocre performance on each platform instead of being amazing at one or two. The ways to get around this is to use automation tools or build a team to take care of it for you.</p>

    <h2>Stay Front of Mind - Follow Them Around</h2>
    <p>Another benefit to using multiple methods of marketing is that you can follow people around. That might not be so easy these days because of the security restrictions being brought in but it is still effective. Apple is updating their devices so cookie tracking is opt-in. It means that when users visit a site, if they haven’t opted into tracking then they can be shown cookie based ads on other sites. You know how amazon ads for a certain product you’ve browsed appear all over the internet, that’s cookie tracking.</p>
    <p>This is one effective method of staying front of mind. When a potential customer has a problem that they need solving and you’re not the only one that can provide the solution, it’s a good idea to hang around them just enough so when they do decide to make that purchase, you’re right there. They will have seen your advertising so much that your brand name becomes etched in their subconscious and when they think of a possible solution, your name comes to them first.</p>
    <p>Each one of your ads can serve a different purpose, you can educate them on a different part of your business with each one. Testimonials from happy previous customers, how your services are better than the competition, any discounts or benefits you have available, all of these things and anything else you can think of can go into your ads. Further building up the potential customer’s familiarity with you.</p>
    
    <h2>It Might Take a While to Find What Works</h2>
    <p>Making a long term plan for marketing strategies other than SEO is good because it could take a long time to find the one that works best. When I say works best, that might not necessarily mean cheapest. It might cost you less but those customers might be more likely to buy cheaper products from you or be more likely to seek a refund after a week.</p>
    <p>A customer that costs more to convert might offer a higher lifetime customer value, that’s the total they spend with you over time. Would you take a customer that converts on day 1 and buys a cheaper product or one that converts after seeing 3 ads but buys more expensive products from you. Both have their benefits but I know which I would rather have more of for the longevity of my business.</p>
    <p>Whether you are running ads, doing SEO or taking advantage of another marketing strategy, it’s not uncommon to go days, weeks, months or even longer without seeing any meaningful results. In general, paid ads can get you results much faster but without great content or some social proof then it could just be money wasted. Don’t think that paying for something is a shortcut, everything else needs to be in place too.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default RelySEO
